import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/page'
import Seo from '../components/seo'
import Layout from '../components/layout'
import ImageBanner from '../components/imageBanner'
import toPlainText from '../components/utils/toPlainText'

const PageTemplate = ({data}) => {
  const blogPost = data && data.blogPost
  let plainText
  if (blogPost._rawContent) {
    const textBlock = blogPost._rawContent.filter(c => c._type === 'textSection')
    if (textBlock.length > 0) {
      const firstBlock = textBlock[0].text
      plainText = toPlainText(firstBlock).substring(0,160).trim().replace(/\s+/g, ' ');
    }
  }
  return (
    <Layout>
      <ImageBanner title={blogPost.title} image={blogPost.bannerImage}/>
      {blogPost && <Seo pageTitle={blogPost.title || 'Untitled'} pageDescription={plainText}/>}
      {blogPost && <Page {...blogPost} />}
    </Layout>
  )
}

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    blogPost: sanityBlogPost(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _createdAt(fromNow: true)
      _rawContent(resolveReferences: {maxDepth: 1000})
      bannerImage {
        asset {
          gatsbyImageData
        }
      }
    }
  }
`

export default PageTemplate
